import { AUTO_TUTORIAL_MODAL } from './constants.tsx';
import { USER_LANGUAGE } from '../translation/constants.ts';
import { logoutAction } from '../actions/logoutAction.ts';

export const logout = async () => {
  await logoutAction();
  cleanUpLocalStorage();
};

const cleanUpLocalStorage = () => {
  const storeKeys = [AUTO_TUTORIAL_MODAL, USER_LANGUAGE];
  const removeKeys = Object.keys(localStorage).filter((key) => !storeKeys.includes(key));

  removeKeys.forEach((item) => {
    localStorage.removeItem(item);
  });
};
